import { Power, PowerOff } from "lucide-react";
import { cn } from "@/lib/utils";
import { Card } from "@/components/ui/card";
import { TDirection, T_XML_option, XML_options } from "src/shared/types/websites-settings";
import { StringBadges } from "src/entities/string-badges";
import { InputWithLabel } from "@/components/ui/input-with-label";

export const DirectionCard = ({
  item,
  selectedDirection,
  setSelectedDirection,
}: {
  item: TDirection;
  selectedDirection: TDirection | null;
  setSelectedDirection: () => void;
}) => {
  const lang_attr = "RU";
  const city_name = item.city.localizations.find((el) => el.locale_code === lang_attr)?.name;

  const XML_badges = item.xml_value?.map((el) =>
    XML_options.find((option) => option.value === el)
  ) as T_XML_option[];

  return (
    <Card
      className={cn(
        "w-full flex flex-col gap-2 p-2 pl-4 text-xs sm:text-sm cursor-pointer durarion-300 hover:opacity-90 hover:scale-[102%]",
        selectedDirection?.direction_id === item.direction_id && "bg-foreground text-background"
      )}
      onClick={setSelectedDirection}>
      <div className={cn("flex w-full items-center gap-3 min-h-10")}>
        <div className="w-full flex flex-col">
          <div className="flex flex-col lg:flex-row gap-2 flex-wrap lg:justify-between lg:gap-x-10 items-start">
            <div className="grid sm:grid-cols-[180px_1fr] gap-2 items-center">
              <div className="grid grid-cols-[44px_1fr] gap-2 items-center">
                <div className="flex items-center gap-1">
                  <div
                    className={`border rounded-full h-5 w-5 flex items-center justify-center ${
                      item.enabled ? "bg-[#4ade8055]" : "bg-secondary"
                    }`}>
                    {item.enabled ? <Power width={12} /> : <PowerOff width={12} />}
                  </div>
                  {item.bestchange_enabled && (
                    <div
                      className={`text-[10px] text-white rounded-full h-5 w-5 flex items-center justify-center bg-[#10b981]`}>
                      B
                    </div>
                  )}
                </div>
                <p>{city_name}</p>
              </div>
              <div className="flex gap-2 justify-between max-w-[200px] font-semibold items-center min-w-[180px]">
                <p>{item.currency_from.currency_code}</p>
                <p className="w-full border-b-4 border-dotted" />
                <p>{item.currency_to.currency_code}</p>
              </div>
            </div>
            <div
              className={cn(
                "text-[10px] leading-[12px] py-1 px-2 min-w-[220px] -ml-1 rounded-md",
                selectedDirection?.direction_id === item.direction_id
                  ? "bg-secondary/20"
                  : "bg-secondary"
              )}>
              <InputWithLabel
                label={`MIN сумма`}
                name={`${item.direction_id}.min_from`}
                type="number"
                variant="small"
                className={"text-card-foreground"}
              />
              <InputWithLabel
                label={`MAX сумма`}
                name={`${item.direction_id}.max_from`}
                type="number"
                variant="small"
                className={"text-card-foreground"}
              />
              <InputWithLabel
                label={`Резерв`}
                name={`${item.direction_id}.reserve`}
                type="number"
                variant="small"
                className={"text-card-foreground"}
              />
            </div>
          </div>
          <StringBadges baseKey="xml" items={XML_badges} />
        </div>
      </div>
    </Card>
  );
};
