import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Form,
  // FormControl,
  // FormField,
  // FormItem,
  // FormLabel,
  // FormMessage,
} from "@/components/ui/form";
// import {
//   InputOTP,
//   InputOTPGroup,
//   InputOTPSlot,
// } from "@/components/ui/input-otp";
import { InputWithLabel } from "@/components/ui/input-with-label";
import { useLogin } from "src/shared/api/query/use-auth/use-auth";
import { useAuthStore } from "src/shared/store/auth-store";
import { user_password_schema, user_username_schema } from "src/shared/validation/field-schemas";
import { ROUTES } from "src/app/routing/routes";

const FormSchema = z.object({
  user_username: user_username_schema,
  user_password: user_password_schema,
  // verify_code: verify_code_schema,
});

export const Auth = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const { me, setMe, setWebsites } = useAuthStore();
  const { mutateAsync: login, isPending } = useLogin();

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    const data = await login({
      user_username: e.user_username,
      user_password: e.user_password,
    });
    if (!!data?.user_username) {
      const { websites, ...me } = data;
      me && setMe(me);
      websites && setWebsites(websites);
    }
  };

  useEffect(() => {
    if (!!me && pathname === ROUTES.AUTH) {
      navigate(ROUTES.DEFAULT);
    }
  }, [me]);

  return (
    <div className="flex w-full h-full min-h-[80vh] items-center justify-center">
      <Card className="p-4 min-w-60">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
            <div className="w-full space-y-2">
              <InputWithLabel
                label="Логин"
                name="user_username"
                type="text"
                autoComplete="username"
              />
              <InputWithLabel
                label="Пароль"
                name="user_password"
                type="password"
                autoComplete="password"
              />
              {/* <FormField
                control={form.control}
                name="verify_code"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>OTP</FormLabel>
                    <FormControl>
                      <InputOTP maxLength={6} {...field}>
                        <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                          <InputOTPSlot index={2} />
                          <InputOTPSlot index={3} />
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              /> */}
            </div>
            <Button type="submit" disabled={isPending}>
              Войти
            </Button>
          </form>
        </Form>
      </Card>
    </div>
  );
};
