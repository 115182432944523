import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Loader } from "@/components/ui/loader";
import { DialogClose } from "@/components/ui/dialog";
import { ModalButton } from "@/components/ui/modal-button";
import { ROUTES } from "src/app/routing/routes";
import { useAuth, useLogout } from "src/shared/api/query/use-auth/use-auth";
import { useAuthStore } from "src/shared/store/auth-store";
import { Breadcrumps } from "../navigation/breadcrumps";
import { Logo } from "src/shared/image/logo";
import door from "src/shared/image/door.svg";
import moon from "src/shared/image/moon.svg";
import sun from "src/shared/image/sun.svg";
import { useLanguages } from "src/shared/api/query/use-common/use-common";
import { useCommonStore } from "src/shared/store/common-store";

export const Header = ({
  theme,
  switchTheme,
  setIsSidebarOpen,
}: {
  theme: "dark" | "light";
  switchTheme: () => void;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, isPending, error } = useAuth();
  const { data: languagesData } = useLanguages();
  const { mutateAsync: logout, isPending: isLogoutPending } = useLogout();
  const { me, setMe, setWebsites } = useAuthStore();
  const { setLanguages } = useCommonStore();

  const onLogout = async () => {
    try {
      await logout();
    } finally {
      setMe(null);
      setWebsites(null);
      navigate(ROUTES.AUTH, { replace: true });
    }
  };

  useEffect(() => {
    if (!!data) {
      const { websites, ...meData } = data;
      meData && setMe(meData);
      websites && setWebsites(websites);
    }
  }, [data]);

  useEffect(() => {
    if (!!languagesData) {
      setLanguages(languagesData?.map((el) => el.name));
    }
  }, [languagesData]);

  useEffect(() => {
    if (!!error && pathname !== ROUTES.AUTH) {
      setMe(null);
      setWebsites(null);
      navigate(ROUTES.AUTH);
    }
  }, [error]);

  return (
    <header className="flex justify-between items-center bg-black text-white px-4 py-2.5">
      <div className="flex items-center gap-3 text-xl w-[200px] pixel">
        <button
          type="button"
          className="shrink-0"
          onClick={() => setIsSidebarOpen((prev) => !prev)}>
          <Logo />
        </button>
        CORE
      </div>
      {isPending && (
        <div className="fixed h-[100vh] w-full bg-secondary inset-0 flex items-center justify-center text-foreground z-[1000]">
          <Loader />
        </div>
      )}
      {!!me && (
        <div className="flex flex-nowrap items-center gap-4 w-full">
          <div className="hidden md:flex">
            <Breadcrumps />
          </div>
        </div>
      )}
      <div className="flex gap-2">
        {!!me && (
          <>
            <div className="rounded-md ring-1 ring-neutral-600 py-1 px-2 text-xs whitespace-pre">
              <p>{me?.user_username}</p>
              <p className="text-neutral-500">{me?.user_role_name}</p>
            </div>
            <ModalButton
              open={isModalOpen}
              setOpen={setIsModalOpen}
              triggerButton={
                <Button
                  size="icon"
                  variant="ghost"
                  className="bg-black hover:bg-neutral-800 min-w-10 ring-1 ring-neutral-600 pr-1">
                  <img src={door} alt="logout" width={28} height={28} />
                </Button>
              }>
              <p>Вы уверены, что хотите выйти?</p>
              <DialogClose asChild>
                <Button
                  type="button"
                  className="justify-self-end"
                  disabled={isLogoutPending}
                  onClick={onLogout}>
                  Выйти
                </Button>
              </DialogClose>
            </ModalButton>
          </>
        )}
        <Button
          size="icon"
          variant="ghost"
          className="bg-black hover:bg-neutral-800 min-w-10 ring-1 ring-neutral-600"
          onClick={switchTheme}>
          <img src={theme === "dark" ? moon : sun} alt="theme" width={20} height={20} />
        </Button>
      </div>
    </header>
  );
};
