import { DeleteButton } from "@/components/ui/delete-button";
import { ECurrencyType } from "src/shared/types/admin";
import { TSelectedPair } from "src/shared/types/websites-settings";

interface SelectedPairCardProps {
  selectedPair: TSelectedPair;
  onDelete: () => void;
}
const SelectedPairCard = ({ selectedPair, onDelete }: SelectedPairCardProps) => {
  const lang_attr = "RU";

  const getFormattedCityName = (city: string) => {
    return city.includes(" ") ? city.replace(" ", "\n") : city;
  };

  return (
    <div className="flex justify-between items-center gap-4 bg-secondary p-1 pl-2 rounded-md">
      <div className="grid grid-cols-[80px_1fr_1fr] gap-8 w-full">
        <div className="flex items-center justify-center min-h-[40px]">
          <span className="whitespace-pre-wrap text-center ml-5">
            {!!selectedPair?.city
              ? getFormattedCityName(
                  selectedPair?.city?.localizations?.find((el) => el.locale_code === lang_attr)
                    ?.name || ""
                )
              : selectedPair?.from?.currency_type === ECurrencyType.CARD ||
                selectedPair?.to?.currency_type === ECurrencyType.CARD
              ? "Авто"
              : ""}
          </span>
        </div>
        <div className="flex items-center justify-center">{selectedPair?.from?.currency_code}</div>
        <div className="flex items-center justify-center">{selectedPair?.to?.currency_code}</div>
      </div>
      <DeleteButton onClick={onDelete} />
    </div>
  );
};

export default SelectedPairCard;
