import { Route, Routes, Outlet } from "react-router-dom";
import { useAuthStore } from "src/shared/store/auth-store";
import { ROUTES } from "./routes";
import { Auth } from "src/pages/auth";
import { Admin } from "src/pages/admin";
import { Bids } from "src/pages/bids";
import { Referral } from "src/pages/referral";
import { WebsiteSettings } from "src/pages/website-settings";
import { EUserRole } from "src/shared/types/admin";

const Routing = () => {
  const { me } = useAuthStore();

  return (
    <Routes>
      <Route path={ROUTES.AUTH} element={<Auth />} />
      {!!me && (
        <Route element={<Layout />}>
          <Route path={ROUTES.DEFAULT} element={<Bids />} />
          <Route path={ROUTES.BIDS} element={<Bids />} />
          <Route path={ROUTES.WEBSITE_SETTINGS} element={<WebsiteSettings />} />
          <Route path={ROUTES.REFERRAL} element={<Referral />} />
          {/* <Route
        path={ROUTES.FEEDBACKS}
        element={<Feedbacks />}
      /> */}
        </Route>
      )}
      {me?.user_role_name === EUserRole.SUPER_ADMIN && (
        <Route path={ROUTES.ADMIN} element={<Admin />} />
      )}
    </Routes>
  );
};

const Layout = () => {
  return <Outlet />;
};

export default Routing;
