import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Card, CardTitle } from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import { SelectWithLabel } from "@/components/ui/select-with-label";
import {
  EWithdrawalStatus,
  TUpdateWithdrawal,
  TWithdrawal,
  withdrawal_status_options,
} from "src/shared/types/referral";
import { useReferralStore } from "src/shared/store/referral-store";
import { useUpdateWithdrawal } from "src/shared/api/query/use-referral/use-withdrawal";

const FormSchema = z.object({
  ref_withdrawal_status: z.custom(() => EWithdrawalStatus),
});

export const WithdrawalForm = ({
  website_slug,
  selectedWithdrawal,
}: {
  website_slug: string;
  selectedWithdrawal: TWithdrawal | null;
}) => {
  const defaultValues = {
    ref_withdrawal_status: EWithdrawalStatus.CREATED,
  };
  const { toast } = useToast();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });
  const { withdrawals, setWithdrawals } = useReferralStore();
  const { mutateAsync: updateWithdrawal, isPending } = useUpdateWithdrawal(website_slug);

  const notModified = !form.formState.isDirty;

  const disabled = isPending || notModified;

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    if (!selectedWithdrawal) {
      return;
    }
    const payload: TUpdateWithdrawal = {
      id: selectedWithdrawal.id,
      ref_sum_was_given: selectedWithdrawal.ref_sum_to_get,
      ref_withdrawal_status: e.ref_withdrawal_status,
    };

    const data = await updateWithdrawal(payload);
    if (data && !!withdrawals) {
      toast({
        title: "Заявки на вывод",
        description: "Данные успешно обновлены",
      });
      setWithdrawals(
        withdrawals.map((el) =>
          el.id === data.id
            ? {
                ...el,
                status: data.status,
              }
            : el
        )
      );
      form.reset({
        ref_withdrawal_status: data.status,
      });
    }
  };

  useEffect(() => {
    if (selectedWithdrawal) {
      form.reset({
        ref_withdrawal_status: selectedWithdrawal.status,
      });
    } else {
      form.reset(defaultValues);
    }
  }, [selectedWithdrawal]);

  return (
    <div className="xl:sticky top-4 h-fit">
      <Card className="w-full max-h-[calc(100vh-32px)] overflow-y-auto border-none lg:border">
        {!!selectedWithdrawal ? (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6 p-4">
              <CardTitle className="text-lg"># {selectedWithdrawal.id_number}</CardTitle>
              <div className="w-full space-y-2">
                <SelectWithLabel
                  label="Статус"
                  name="ref_withdrawal_status"
                  placeholder="Измените статус заявки"
                  options={withdrawal_status_options?.filter((el) =>
                    selectedWithdrawal.status !== EWithdrawalStatus.CREATED
                      ? el.value !== EWithdrawalStatus.COMPLETED
                      : true
                  )}
                />
              </div>
              <Button type="submit" disabled={disabled}>
                Обновить
              </Button>
            </form>
          </Form>
        ) : (
          <div className="flex items-center justify-center bg-secondary h-[300px] text-neutral-500">
            Выберите заявку
          </div>
        )}
      </Card>
    </div>
  );
};
