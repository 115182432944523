import { useState, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "@/components/ui/toaster";
import "./index.css";
import { Header } from "./widgets/header";
import Routing from "./app/routing/routing";
import { useAuthStore } from "./shared/store/auth-store";
import { LS } from "./shared/constants";
import { Sidebar } from "./widgets/sidebar";

function App() {
  const { me } = useAuthStore();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [theme, setTheme] = useState<"dark" | "light">("light");

  const switchTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    localStorage.setItem(LS.THEME, newTheme);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem(LS.THEME) as "dark" | "light" | null;
    const systemPrefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

    if (savedTheme) {
      setTheme(savedTheme);
    } else {
      const initialTheme = systemPrefersDark ? "dark" : "light";
      setTheme(initialTheme);
      localStorage.setItem(LS.THEME, initialTheme);
    }
  }, []);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 1,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <div className={`${theme} flex flex-col h-full min-h-[100vh]`}>
        <Header theme={theme} switchTheme={switchTheme} setIsSidebarOpen={setIsSidebarOpen} />
        <main
          className={`h-full grid flex-1 bg-background ${
            !!me ? "grid-cols-[1px_1fr] sm:grid-cols-[60px_1fr]" : ""
          }`}>
          {!!me && <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />}
          <div className="w-full pb-10">
            <Routing />
          </div>
        </main>
        <Toaster />
      </div>
    </QueryClientProvider>
  );
}

export default App;
