import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Emptiness } from "@/components/ui/emptiness";
import { Loader } from "@/components/ui/loader";
import { PaginationButtons } from "@/components/ui/pagination-buttons";
import { WebsitesList } from "src/entities/websites-list";
import { useCommonStore } from "src/shared/store/common-store";
import { useReferralStore } from "src/shared/store/referral-store";
import { useWithdrawals } from "src/shared/api/query/use-referral/use-withdrawal";
import { TWithdrawal, withdrawal_status_options } from "src/shared/types/referral";
import { WithdrawalForm } from "./components/withdrawal-form";
import { WithdrawalFilters } from "./components/withdrawal-filters";
import { WithdrawalColumn } from "./components/withdrawal-column";

export const Withdrawals = () => {
  const [searchParams] = useSearchParams();
  const website_slug = searchParams.get("website");

  const { withdrawals, setWithdrawals, withdrawalsTotal, setWithdrawalsTotal } = useReferralStore();
  const { limit } = useCommonStore();

  const [params, setParams] = useState(`limit=${limit}&offset=0`);
  const [selectedWithdrawal, setSelectedWithdrawal] = useState<TWithdrawal | null>(null);

  const { data, isPending } = useWithdrawals(website_slug || "", params);

  useEffect(() => {
    if (!!data?.data) {
      setWithdrawals(data.data);
      setWithdrawalsTotal(data.total);
    } else {
      setWithdrawals(null);
    }
  }, [data]);

  const totalPages = Math.ceil((withdrawalsTotal || 1) / limit);

  const scrollListener = (list: HTMLElement) => {
    list.scrollIntoView();
  };

  useEffect(() => {
    const list = document.getElementById("list");
    if (!list) {
      return;
    }
    list.addEventListener("scrollend", () => scrollListener(list));
    return () => list.removeEventListener("scrollend", () => scrollListener(list));
  }, [withdrawals?.length]);

  return (
    <div className="w-full flex flex-col xl:flex-row gap-4">
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="w-full sm:w-72 2xl:w-80 flex flex-col gap-4">
          <WebsitesList />
        </div>
      </div>
      {!!website_slug && (
        <div className="w-full flex flex-col gap-1">
          <WithdrawalFilters setParams={setParams} />
          {isPending ? (
            <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
              <Loader />
            </div>
          ) : !!withdrawals?.length ? (
            <div
              id="list"
              className="relative w-full max-w-[calc(100vw-32px)] overflow-x-auto md:overflow-visible snap-x snap-always snap-mandatory scroll-auto">
              <div className="flex gap-2 md:grid grid-cols-3">
                {withdrawal_status_options?.map((status) => {
                  const items = withdrawals?.filter((el) => el.status === status.value);
                  return (
                    <WithdrawalColumn
                      key={status.value}
                      title={status.label}
                      items={items}
                      selectedWithdrawal={selectedWithdrawal}
                      setSelectedWithdrawal={setSelectedWithdrawal}
                    />
                  );
                })}
              </div>
              <Dialog open={!!selectedWithdrawal} onOpenChange={() => setSelectedWithdrawal(null)}>
                <DialogContent className="max-w-[90vw] rounded-lg sm:max-w-[425px] p-0">
                  <WithdrawalForm
                    website_slug={website_slug}
                    selectedWithdrawal={selectedWithdrawal}
                  />
                </DialogContent>
              </Dialog>
            </div>
          ) : (
            <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
              <Emptiness message="Заявки не найдены" />
            </div>
          )}
          {totalPages > 1 && (
            <div className="sticky bottom-0">
              <PaginationButtons totalPages={totalPages} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
