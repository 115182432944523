import { TNavIcon, TRoute } from "src/shared/types/common";
import { Users } from "src/pages/admin/users/users";
import { Logo } from "src/shared/image/logo";
import { Websites } from "src/pages/admin/websites/websites";
// import Star from "src/shared/image/star";
import { Glasses } from "src/shared/image/glasses";
import { Website } from "src/shared/image/website";
import { Pacman } from "src/shared/image/pacman";
import { EUserRole } from "src/shared/types/admin";
import { TaskManager } from "src/pages/referral/task-manager/task-manager";
import { ManualCheating } from "src/pages/referral/manual-cheating/manual-cheating";
import { Withdrawals } from "src/pages/referral/withdrawals/withdrawals";
import { Currencies } from "src/pages/admin/currencies/currencies";
import { WebsiteCurrencies } from "src/pages/website-settings/website-currencies/website-currencies";
import { WebsiteDirections } from "src/pages/website-settings/website-directions/website-directions";

export enum ROUTES {
  DEFAULT = "/",
  AUTH = "/auth",
  ADMIN = "/admin",
  BIDS = "/bids",
  WEBSITE_SETTINGS = "/website-settings",
  FEEDBACKS = "/feedbacks",
  REFERRAL = "/referral",
}

export const navigation: TNavIcon[] = [
  {
    path: ROUTES.BIDS,
    icon: Logo,
    title: "Заявки",
  },
  {
    path: ROUTES.WEBSITE_SETTINGS,
    icon: Website,
    title: "Управление сайтами",
  },
  {
    path: ROUTES.REFERRAL,
    icon: Pacman,
    title: "Реферальная программа",
  },
  // {
  //   path: ROUTES.FEEDBACKS,
  //   icon: Star,
  //   title: "Отзывы",
  // },
  {
    path: ROUTES.ADMIN,
    icon: Glasses,
    title: "Админ",
    permissions: [EUserRole.SUPER_ADMIN],
  },
];

// website

enum WEBSITE_SETTINGS_PATHS {
  CURRENCIES = "currencies",
  DIRECTIONS = "directions",
  RESERVES = "reserves",
}

export const WEBSITE_SETTINGS_ROUTES: TRoute[] = [
  {
    path: WEBSITE_SETTINGS_PATHS.CURRENCIES,
    component: WebsiteCurrencies,
    title: "Валюты",
  },
  {
    path: WEBSITE_SETTINGS_PATHS.DIRECTIONS,
    component: WebsiteDirections,
    title: "Направления",
  },
];

// referral

enum REFERRAL_PATHS {
  TASK_MANAGER = "task-manager",
  MANUAL_CHEATING = "manual-cheating",
  WITHDRAWALS = "withdrawals",
}

export const REFERRAL_ROUTES: TRoute[] = [
  {
    path: REFERRAL_PATHS.TASK_MANAGER,
    component: TaskManager,
    title: "TASK менеджер",
  },
  {
    path: REFERRAL_PATHS.MANUAL_CHEATING,
    component: ManualCheating,
    title: "Ручная накрутка",
  },
  {
    path: REFERRAL_PATHS.WITHDRAWALS,
    component: Withdrawals,
    title: "Выводы",
  },
];

// admin

enum ADMIN_PATHS {
  USERS = "users",
  WEBSITES = "websites",
  CURRENCIES = "currencies",
}

export const ADMIN_ROUTES: TRoute[] = [
  {
    path: ADMIN_PATHS.USERS,
    component: Users,
    title: "Пользователи",
  },
  {
    path: ADMIN_PATHS.WEBSITES,
    component: Websites,
    title: "Сайты",
  },
  {
    path: ADMIN_PATHS.CURRENCIES,
    component: Currencies,
    title: "Валюты",
  },
];
