// task_manager

import { TSelect } from "./common";

export type TReferralTask = {
  task_id: string;
  task_website_slug: string;
  task_website_name: string;
  task_comment: string;
  task_ref_id: number;
  task_min_bid_amount: number;
  task_max_bid_amount: number;
  task_min_amount_day: number;
  task_max_amount_day: number;
  task_is_enabled: boolean;
  task_is_deleted: boolean;
  task_last_executed_at: string;
};

export type TCreateReferralTask = {
  task_website_slug: string;
  task_ref_id: number;
  task_comment: string;
  task_min_bid_amount: number;
  task_max_bid_amount: number;
  task_min_amount_day: number;
  task_max_amount_day: number;
};

export type TUpdateReferralTask = {
  task_id: string;
  task_min_bid_amount: number;
  task_max_bid_amount: number;
  task_min_amount_day: number;
  task_max_amount_day: number;
  task_is_enabled?: boolean;
};

export type TReferralTaskHistoryItem = {
  task_id: string;
  task_history_id: string;
  task_website_name: string;
  task_ref_id: number;
  task_bid_count: number;
  task_amount_day: number;
  task_finished_at: string;
};

export type TGraph = TGraphDataItem[];

export type TGraphDataItem = {
  date: string;
  close: number;
};

// manual_cheating

export type TManualCheating = {
  website_slug: string;
  ref_id: number;
  total_amount: number;
  bids_count: number;
};

// withdrawal

export enum EWithdrawalStatus {
  CREATED = "created",
  COMPLETED = "completed",
  CANCELED = "canceled",
}

export type TWithdrawalFilters = {
  user_rid?: boolean;
  user_uuid?: boolean;
  status?: boolean;
  withdrawal_date_from?: boolean;
  withdrawal_date_to?: boolean;
};

export const withdrawal_status_options: TSelect[] = [
  {
    label: "Новая",
    value: EWithdrawalStatus.CREATED,
  },
  {
    label: "Исполнена",
    value: EWithdrawalStatus.COMPLETED,
  },
  {
    label: "Отклонена",
    value: EWithdrawalStatus.CANCELED,
  },
];

export type TWithdrawalPagination = {
  total: number;
  page: number;
  data: TWithdrawal[];
};

export type TWithdrawal = {
  ref_uuid: string;
  user_rid: string;
  address: string;
  ref_sum_to_get: number;
  id: string;
  id_number: number;
  status: EWithdrawalStatus;
  created_at: string;
  updated_at: string;
  user_username?: string;
};

export type TUpdateWithdrawal = {
  id: string;
  ref_sum_was_given: number;
  ref_withdrawal_status: EWithdrawalStatus;
};
