import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { EUserRole, TUser, TUserColumn, colorByUserRole } from "src/shared/types/admin";
import { UserCard } from "./users-card";
import { Loader } from "@/components/ui/loader";
import { Card } from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { Emptiness } from "@/components/ui/emptiness";
import { useAdminStore } from "src/shared/store/admin-store";
import { useUsers } from "src/shared/api/query/use-admin/use-users";
import { checkStatusCode } from "src/shared/utils/common";

export const UsersList = ({
  selectedUser,
  setSelectedUser,
}: {
  selectedUser: TUser | null;
  setSelectedUser: Dispatch<SetStateAction<TUser | null>>;
}) => {
  const { toast } = useToast();
  const { users, setUsers } = useAdminStore();
  const { data, isPending, error } = useUsers();

  const usersByRoles: TUserColumn[] | null = useMemo(() => {
    if (!!users?.length) {
      return Object.values(EUserRole)
        .map((role) => ({
          user_role_name: role,
          color: colorByUserRole[role],
          users: users?.filter((user) => user.user_role_name === role),
        }))
        ?.filter((el) => !!el.users?.length);
    } else {
      return null;
    }
  }, [users]);

  useEffect(() => {
    if (!!data) {
      setUsers(data);
    } else {
      setUsers(null);
    }
  }, [data]);

  useEffect(() => {
    if (!!error) {
      const baseToast = {
        variant: "destructive" as const,
        title: "Пользователи",
      };
      if (checkStatusCode([error], 403)) {
        toast({ ...baseToast, description: "Недостаточно прав" });
      } else if (checkStatusCode([error], 500)) {
        toast({ ...baseToast, description: "Не удалось получить пользователей" });
      } else {
        toast({ ...baseToast, description: "Неизвестная ошибка" });
      }
    }
  }, [error]);

  const [isWide, setIsWide] = useState(window.innerWidth > 1340);

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth > 1340);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`w-full flex flex-col gap-2 ${
        usersByRoles?.length
          ? `sm:w-[280px] ${isWide ? "lg:w-fit lg:grid lg:overflow-auto" : ""}`
          : "bg-secondary rounded-md"
      }`}
      style={{
        gridTemplateColumns: `repeat(${usersByRoles?.length}, minmax(300px, 1fr))`,
      }}>
      {!!usersByRoles ? (
        usersByRoles?.map((role_el) => (
          <Card
            key={role_el.user_role_name}
            className="flex flex-col gap-1 w-full min-w-[300px] bg-secondary p-1">
            <Card
              className="p-4 uppercase"
              style={{
                color: role_el.color,
              }}>
              {role_el.user_role_name}
            </Card>
            {role_el?.users?.map((el) => (
              <UserCard
                key={el.user_id}
                item={el}
                color={role_el.color}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            ))}
          </Card>
        ))
      ) : isPending ? (
        <Loader />
      ) : (
        <Emptiness message="" />
      )}
    </div>
  );
};
