import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Emptiness } from "@/components/ui/emptiness";
import { Loader } from "@/components/ui/loader";
import { PaginationButtons } from "@/components/ui/pagination-buttons";
import { Button } from "@/components/ui/button";
import { TCreateDirection, TDirection } from "src/shared/types/websites-settings";
import { useCommonStore } from "src/shared/store/common-store";
import { useWebsiteStore } from "src/shared/store/website-store";
import { DirectionCard } from "./direction-card";
import { DirectionForm } from "./direction-form";
import { DirectionFilters } from "./direction-filters";
import { Checkbox } from "@/components/ui/checkbox";
import { cn } from "@/lib/utils";
import { Card } from "@/components/ui/card";
import { Minus, Plus } from "lucide-react";
import { Form } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { string_schema } from "src/shared/validation/field-schemas";
import {
  useUpdateDirection,
  useDirections,
} from "src/shared/api/query/use-website-settings/use-directions";
import { toast } from "@/components/ui/use-toast";
import { fixMinimalExponent } from "src/shared/utils/charfix";

const filterFields = {
  city_code: true,
  currency_from_code: true,
  currency_to_code: true,
  xml_value: true,
  enabled: true,
  bestchange_enabled: true,
  // direction_course_is_manual: true,
};

export const DirectionsList = ({
  website_slug,
  selectedDirection,
  setSelectedDirection,
  isPending,
  params,
  setParams,
}: {
  website_slug: string;
  selectedDirection: TDirection | null;
  setSelectedDirection: Dispatch<SetStateAction<TDirection | null>>;
  isPending: boolean;
  params: string;
  setParams: Dispatch<SetStateAction<string>>;
}) => {
  const { mutateAsync: updateDirection } = useUpdateDirection(website_slug || "");
  const { directions, setDirections, directionsTotal, setDirectionsTotal } = useWebsiteStore();
  const [isMassUpdate, setMassUpdate] = useState(false);
  const { limit } = useCommonStore();
  const totalPages = Math.ceil((directionsTotal || 1) / limit);
  const [massSelectedDirections, setMassSelectedDirections] = useState<TCreateDirection[]>([]);

  const FormSchema = z.record(
    z.object({
      city_code: string_schema,
      currency_from_code: string_schema,
      currency_to_code: string_schema,

      min_from: string_schema,
      max_from: string_schema,
      reserve: string_schema,
    })
  );

  const defaultValues = {
    "1": {
      city_code: "",
      currency_from_code: "",
      currency_to_code: "",
      min_from: "0",
      max_from: "0",
      reserve: "0",
    },
  };

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  const massUpdateCheckHandler = (isSelected: boolean, direction: TDirection) => {
    if (isSelected) {
      setMassSelectedDirections(
        massSelectedDirections?.filter(
          (el) =>
            `${direction.city.city_code}_${direction.currency_from.currency_code}_${direction.currency_to.currency_code}` !==
            `${el.city_code}_${el.currency_from_code}_${el.currency_to_code}`
        )
      );
    } else {
      setMassSelectedDirections(
        massSelectedDirections.concat([
          {
            city_code: direction.city.city_code,
            currency_from_code: direction.currency_from.currency_code,
            currency_to_code: direction.currency_to.currency_code,
            min_from: direction.min_from,
            max_from: direction.max_from,
            reserve: direction.reserve,
            xml_value: direction.xml_value,
            // direction_course_is_manual: direction.direction_course_is_manual,
          },
        ])
      );
    }
  };

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    const dirtyKeys = Object.keys(form.formState.dirtyFields);
    if (!dirtyKeys?.length) {
      return;
    }

    const payload: TCreateDirection[] = Object.entries(e)
      ?.filter((el) => dirtyKeys.includes(el[0]?.toString()))
      ?.map((el) => {
        return {
          direction_id: el[0],
          ...el[1],

          min_from: +el[1].min_from,
          max_from: +el[1].max_from,
          reserve: +el[1].reserve,
        };
      });

    const data = await updateDirection(payload);
    if (data && !!directions) {
      toast({
        title: "Резервы",
        description: "Данные успешно обновлены",
      });

      const updatedDirections = directions?.map((el) => {
        const currentEl = data?.find((data_el) => data_el.direction_id === el.direction_id);
        if (!!currentEl) {
          return currentEl;
        } else {
          return el;
        }
      });
      setDirections(updatedDirections);
    }
  };

  const { data: directionsData } = useDirections(website_slug || "", params);

  useEffect(() => {
    if (!!directionsData) {
      setDirectionsTotal(directionsData.total);
      setDirections(directionsData.data);
      const obj = {};
      directionsData.data.forEach((el) => {
        obj[el.direction_id] = {
          city_code: el.city.city_code,
          currency_from_code: el.currency_from.currency_code,
          currency_to_code: el.currency_to.currency_code,

          min_from: fixMinimalExponent(el.min_from),
          max_from: el.max_from.toString(),
          reserve: el.reserve.toString(),
        };
      });
      form.reset(obj);
    } else {
      setDirectionsTotal(null);
      setDirections(null);
      form.reset(defaultValues);
    }
  }, [directionsData]);

  return (
    <div className={`flex flex-col gap-1 min-w-[220px]`}>
      {website_slug && (
        <div className="relative flex flex-col gap-2 md:grid grid-cols-[320px_1fr]">
          <DirectionForm
            isMassUpdate={isMassUpdate}
            website_slug={website_slug}
            selectedDirection={selectedDirection}
            massSelectedDirections={massSelectedDirections}
            setSelectedDirection={setSelectedDirection}
          />
          <div className="flex flex-col gap-1">
            <DirectionFilters
              website_slug={website_slug}
              setParams={setParams}
              fields={filterFields}
            />
            <Card
              className={cn("flex flex-col gap-2 px-4 py-2", isMassUpdate && "bg-green-400/50")}>
              <div
                onClick={() => {
                  setSelectedDirection(null);
                  setMassSelectedDirections([]);
                  setMassUpdate(!isMassUpdate);
                }}
                className="flex gap-3 cursor-pointer">
                {isMassUpdate ? (
                  <Minus className="pb-px w-5 h-5" />
                ) : (
                  <Plus className="pb-px w-5 h-5" />
                )}
                Массовое редактирование
              </div>
              {isMassUpdate && (
                <div className="flex gap-4">
                  <Button
                    type="button"
                    variant="link"
                    className="p-0 text-xs h-fit"
                    onClick={() => {
                      !!directions &&
                        setMassSelectedDirections(
                          directions.map((direction) => ({
                            city_code: direction.city.city_code,
                            currency_from_code: direction.currency_from.currency_code,
                            currency_to_code: direction.currency_to.currency_code,
                            min_from: direction.min_from,
                            max_from: direction.max_from,
                            reserve: direction.reserve,
                            xml_value: direction.xml_value,
                          }))
                        );
                    }}>
                    Выбрать все
                  </Button>
                  <Button
                    type="button"
                    variant="link"
                    className="p-0 text-xs h-fit"
                    onClick={() => setMassSelectedDirections([])}>
                    Сбросить все
                  </Button>
                </div>
              )}
            </Card>
            {isPending ? (
              <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
                <Loader />
              </div>
            ) : !!directions?.length ? (
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="w-full flex flex-col space-y-1">
                  {directions?.map((direction) => {
                    const isSelected = isMassUpdate
                      ? !!massSelectedDirections?.find(
                          (el) =>
                            `${direction.city.city_code}_${direction.currency_from.currency_code}_${direction.currency_to.currency_code}` ===
                            `${el.city_code}_${el.currency_from_code}_${el.currency_to_code}`
                        )
                      : false;
                    return (
                      <div
                        key={direction.direction_id}
                        className={cn(
                          "flex gap-2 items-center",
                          isMassUpdate && (isSelected ? "" : "opacity-30")
                        )}>
                        {isMassUpdate && (
                          <Checkbox
                            checked={isSelected}
                            onCheckedChange={() => massUpdateCheckHandler(isSelected, direction)}
                          />
                        )}
                        <DirectionCard
                          item={direction}
                          selectedDirection={selectedDirection}
                          setSelectedDirection={() => {
                            if (isMassUpdate) {
                              massUpdateCheckHandler(isSelected, direction);
                            } else {
                              setSelectedDirection(isSelected ? null : direction);
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                  <div className="sticky bottom-0 flex flex-col gap-2">
                    <Button disabled={isPending} className="self-end" type="submit">
                      Сохранить
                    </Button>
                    {totalPages > 1 && <PaginationButtons totalPages={totalPages} />}
                  </div>
                </form>
              </Form>
            ) : (
              <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
                <Emptiness message="Направления не найдены" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
