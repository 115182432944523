import { Dispatch, SetStateAction, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import qs from "query-string";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Card } from "@/components/ui/card";
import { MultiSelect } from "@/components/ui/multi-select";
import { string_schema } from "src/shared/validation/field-schemas";
import { useCommonStore } from "src/shared/store/common-store";
import { StringBadges } from "src/entities/string-badges";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { DatePicker } from "@/components/ui/date-picker";
import { arrayGuard } from "src/shared/utils/common";
import {
  EWithdrawalStatus,
  TWithdrawalFilters,
  withdrawal_status_options,
} from "src/shared/types/referral";

const FormSchema = z.object({
  status: z.array(z.custom(() => EWithdrawalStatus)).nullable(),
  user_rid: string_schema.nullable(),
  user_uuid: string_schema.nullable(),
  withdrawal_date_from: string_schema.nullable(),
  withdrawal_date_to: string_schema.nullable(),
});

export const WithdrawalFilters = ({
  setParams,
}: {
  setParams: Dispatch<SetStateAction<string>>;
}) => {
  const { limit } = useCommonStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const defaultParamsFirstPage = `limit=${limit}&offset=0`;
  const defaultParams = `limit=${limit}&offset=${(+page - 1) * limit}`;

  const defaultValues = {
    user_rid: null,
    user_uuid: null,
    status: null,
    withdrawal_date_from: null,
    withdrawal_date_to: null,
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  const isDirty = Object.values(form.watch())?.some((el) => el !== null);

  const status = arrayGuard(form.watch("status") as EWithdrawalStatus[]);

  const handleSelect = (attr: "status", e: string) => {
    const value = form.watch(attr) || [];
    if (value?.includes(e)) {
      form.setValue(
        attr,
        value?.filter((el) => el !== e)
      );
    } else {
      form.setValue(attr, value.concat([e]));
    }
  };

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    let updParams = defaultParamsFirstPage;
    searchParams.set("page", "1");
    Object.entries(e)?.forEach((el) => {
      if (
        el[1] !== null &&
        el[1] !== undefined &&
        (Array.isArray(el[1]) ? !!el[1]?.length : true)
      ) {
        if (Array.isArray(el[1]) && !!el[1]?.length) {
          searchParams.set(el[0], el[1]?.join());
          el[1]?.forEach((element) => (updParams += `&${el[0]}=${element}`));
        } else {
          searchParams.set(el[0], el[1]?.toString());
          updParams += `&${el[0]}=${el[1]}`;
        }
      } else {
        searchParams.delete(el[0]);
      }
    });
    setParams(updParams);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!!searchParams) {
      const parsedParams = qs.parse(defaultParams + "&" + searchParams.toString(), {
        arrayFormat: "comma",
      });
      Object.keys(defaultValues).forEach((el) => {
        const value = parsedParams[el];
        if (!!value) {
          form.setValue(el as keyof TWithdrawalFilters, value as unknown as string[]);
        }
      });
      const updParams = qs.stringify(parsedParams, {
        skipNull: true,
        skipEmptyString: true,
      });
      if (updParams !== defaultParamsFirstPage) {
        setParams(updParams);
      }
    }
  }, [page]);

  return (
    <Card className="p-4 bg-secondary/50">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex flex-col gap-2">
          <div className="flex flex-col md:flex-row justify-between gap-1.5">
            <div className="relative w-full">
              <Search className="absolute top-1/2 -translate-y-1/2 left-2 w-5 h-5 opacity-50" />
              <Input placeholder="Найти по rid" className="pl-9" {...form.register("user_rid")} />
            </div>
            <div className="rounded-md border bg-background w-fit">
              <DatePicker start_key="withdrawal_date_from" end_key="withdrawal_date_to" />
            </div>
          </div>
          <div className="flex flex-col xl:grid grid-cols-2 gap-x-4 gap-y-1">
            <div className="w-full">
              <MultiSelect
                options={withdrawal_status_options}
                className="w-full"
                placeholder="Статус..."
                selected={status?.map((code) => ({
                  label: withdrawal_status_options.find((el) => el.value === code)?.label || code,
                  value: code,
                }))}
                setFn={(e) => handleSelect("status", e)}
              />
              {!!status && !!status?.length && (
                <div className="flex gap-1">
                  <StringBadges
                    baseKey="status"
                    items={status?.map((code) => ({
                      label:
                        withdrawal_status_options.find((el) => el.value === code)?.label || code,
                      value: code,
                    }))}
                    onDelete={(e) => handleSelect("status", e)}
                  />
                </div>
              )}
            </div>
            <div className="flex gap-2 justify-self-end pt-4 xl:pt-2">
              {isDirty && (
                <Button
                  type="button"
                  size="sm"
                  variant="outline"
                  onClick={() => {
                    form.reset(defaultValues);
                    setParams(defaultParams);
                    Object.keys(defaultValues).forEach((el) => searchParams.delete(el));
                    setSearchParams(searchParams);
                  }}>
                  Сбросить фильтры
                </Button>
              )}
              <Button type="submit" size="sm">
                Получить данные
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </Card>
  );
};
