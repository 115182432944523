import { Dispatch, SetStateAction } from "react";
import { cn } from "@/lib/utils";
import { Card } from "@/components/ui/card";
import { CopyButton } from "@/components/ui/copy-button";
import { TWithdrawal } from "src/shared/types/referral";
import { formatHash } from "src/shared/utils/charfix";
import { formatDate } from "src/shared/utils/date";

export const WithdrawalCard = ({
  item,
  selectedWithdrawal,
  setSelectedWithdrawal,
}: {
  item: TWithdrawal;
  selectedWithdrawal: TWithdrawal | null;
  setSelectedWithdrawal: Dispatch<SetStateAction<TWithdrawal | null>>;
}) => {
  const isSelected = selectedWithdrawal?.id === item.id;
  const created = formatDate(new Date(item.created_at));
  const updated = formatDate(new Date(item.updated_at));

  const handleSelect = () => {
    setSelectedWithdrawal(item);
  };

  return (
    <Card
      className={cn(
        `w-full flex flex-col gap-2 p-2 durarion-300 hover:opacity-90 hover:scale-[101%]`,
        isSelected && "text-background bg-foreground"
      )}>
      <div className="flex w-full gap-x-2 lg:items-center">
        <div className="flex flex-col lg:flex-row w-full gap-2 lg:items-center">
          <div
            className={cn(
              "w-fit rounded-md px-2 text-lg whitespace-pre cursor-pointer",
              "bg-foreground text-background"
            )}
            onClick={handleSelect}>
            # {item.id_number}
          </div>
          <CopyButton
            label={formatHash(item.id)}
            value={item.id}
            variant={isSelected ? "default" : "outline"}
          />
        </div>
        <div
          className="w-full text-right text-[10px] cursor-pointer leading-3"
          onClick={handleSelect}>
          {!!item.updated_at && created !== updated && <p className="pr-1">Обновлена: {updated}</p>}
          <p className="pr-1">Создана: {created}</p>
        </div>
      </div>
      <div className="text-xs font-light">
        <div className="cursor-pointer" onClick={handleSelect}>
          <div>
            RID: <b className="pl-2 text-base">{item.user_rid}</b>
          </div>
          <div>Кошелек:</div>
        </div>
        <CopyButton
          label={item.address}
          value={item.address}
          className="text-xs px-2"
          variant={isSelected ? "default" : "outline"}
        />
        <div className="pt-2 cursor-pointer" onClick={handleSelect}>
          Сумма: <b className="pl-2 text-base xl:text-lg">{item.ref_sum_to_get}</b> USDT
        </div>
        <div className="pt-2 cursor-pointer">
          Никнейм: <b className="pl-2 text-base xl:text-lg">{item.user_username}</b>
        </div>
      </div>
    </Card>
  );
};
